/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

#landing {
	width: 100%;
	height: 400px;
	display: table;
	background: #000;
	position: relative;
	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide,
	.slide,
	.slick-slide > div {
		height: 100%;
	}
	.slides {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.slider {
		.slide {
			width: 100%;
			background: no-repeat center center / cover;
		}
	}
	.container {
		max-width: 1500px;
	}
	.inner {
		display: table-cell;
		vertical-align: bottom;
		padding: 100px 30px;
		position: relative;
		z-index: 2;
		color: #FFF;
		font-size: 20px;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #FFF;
	}
	.text {
		* {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include mbp(768) {
		.inner {
			padding: 150px 5%;
		}
	}
	@include mbp(960) {
		height: 600px;
		h1 {
			font-size: 60px;
		}
	}
	@include mbp(1200) {
		height: 800px;
		height: 100vh;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 50%;
			left: 0;
			background: linear-gradient(#000, transparent);
			opacity: 0.3;
		}
	}
}

.cssanimations {
	#landing {
		.text {
			opacity: 0;
		}
	}
	.pace-done {
		#landing {
			.text {
				animation: fadeInUp 0.5s forwards;
			}
		}
	}
}
