/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.section-testimonials {
	padding: 60px 20px;
	position: relative;
	.container {
		width: 1200px;
		max-width: 100%;
	}
	.slick-track {
		width: 100%;
		display: flex;
		align-items: stretch;
	}
	.slide {
		.photo {
			img {
				width: auto;
			}
		}
	}
	.photo {
		margin: 0 0 50px;
		img {
			margin: 0 auto;
			border-radius: 100%;
		}
	}
	.text {
		font-size: 20px;
		font-style: italic;
		text-align: center;
	}
	.name {
		font-size: 16px;
		font-style: normal;
	}
	.slick-dots {
		margin-top: 50px;
		position: relative;
		bottom: auto;
		li {
			button {
				background: #DDD;
				border: none;
			}
			&.slick-active {
				button {
					background: $color1;
				}
			}
		}
	}
	h2 {
		text-align: center;
		margin-bottom: 40px;
	}
	@include mbp(768) {
		min-height: 500px;
		display: flex;
		align-items: center;
		padding: 100px 5% 60px;
		.text {
			font-size: 30px;
		}
	}
	@include mbp(1500) {
		min-height: 600px;
	}
}
