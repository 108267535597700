/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// FORMS
// ---------------

form {
	font-size: 16px;
}

fieldset {
	margin: 30px 0;
	padding: 0;
	border: none;
	legend {
		margin: 0 0 20px;
		font-weight: 700;
		font-size: 20px;
	}
	&:first-child {
		margin-top: 0;
	}
}

label {
	display: block;
	font-weight: 700;
	margin: 0 0 3px;
	&.required::after {
		content: "*";
	}
}

#{$all-text-inputs},
select[multiple=multiple],
select {
	appearance: none;
	width: 100%;
	height: 48px;
	padding: 0 15px;
	outline: none;
	font-size: 16px;
	font-weight: 300;
	font-family: $baseFontFamily;
	color: $textColor;
	border: 1px solid #CCC;
	background: #FFF;
	border-radius: 0;
	box-shadow: none;
	line-height: 1;
}

select {
	height: 48px;
	padding: 0 40px 0 15px;
	background: #FFF url(assets/img/select.png) no-repeat right center;
	@include hidpi(2) {
		background-image: url(assets/img/select@2x.png);
		background-size: 33px 8px;
	}
}

input[type="radio"],
input[type="checkbox"] {
	margin: 0;
	cursor: pointer;
}

input[type="search"] {
	appearance: none;
}

textarea {
	width: 100%;
	height: 150px;
	padding: 15px;
	resize: vertical;
	overflow: auto;
}

select[multiple],
select[size] {
	height: auto;
}

::-moz-focus-inner {
	border: 0;
	padding: 0;
}

#{$all-button-inputs},
button,
.button,
.btn,
.wp-block-button__link {
	padding: 10px 25px;
	display: inline-block;
	font-weight: 700;
	font-family: $secondaryFontFamily;
	vertical-align: middle;
	cursor: pointer;
	color: $color1;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	font-size: 16px;
	line-height: 1;
	border: 2px solid $color1;
	border-radius: 100px;
	outline: none;
	background: none;
	appearance: none;
	transition: all 0.2s ease-in-out;
	&.btn-fa-left {
		.fa {
			margin-right: 5px;
		}
	}
	&.btn-fa-right {
		.fa {
			margin-left: 5px;
		}
	}
	&.large {
		padding: 20px 40px;
		font-size: 16px;
	}
	&:hover,
	&:active {
		color: #FFF;
		text-decoration: none;
		background: $color2;
		border-color: $color2;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
	@include mbp(768) {
		&.large {
			padding: 25px 60px;
			font-size: 20px;
		}
	}
}

input[type="image"] {
	border: 0;
}

.alert-success,
.alert-notice,
.alert-error,
.gform_confirmation_message,
#cancel-comment-reply-link {
	display: block;
	margin: 0 0 $baseTypeSpacing;
	padding: 10px 20px;
	border: none;
	border-radius: 0;
	font-size: 16px;
	font-weight: 300;
	background: #F7F6F7;
	color: #333;
	border-left: 4px solid transparent;
	strong {
		font-weight: 400;
	}
	ul {
		margin: 0;
		li {
			display: block;
			margin: 0;
		}
	}
	.fa {
		margin-right: 3px;
	}
}

.alert-success,
.gform_confirmation_message {
	border-left-color: #8FAE1B;
	.fa {
		color: #8FAE1B;
	}
}

.alert-notice {
	border-left-color: #1E85BE;
	.fa {
		color: #1E85BE;
	}
}

.alert-error,
#cancel-comment-reply-link {
	border-left-color: #B81C23;
	.fa {
		color: #B81C23;
	}
}

.form-row {
	margin: 0 0 20px;
}

div.gform_wrapper {
	max-width: 100%;
	margin: 0;
	.gform_heading {
		margin: 0 auto;
		padding: 0;
		h3.gform_title {
			margin: 0;
			padding: 0;
			text-align: center;
			font-weight: 400;
			font-size: 36px;
		}
	}
	.validation_error {
		font-size: $baseFontSize;
		line-height: 1.2;
	}
	div.gform_body {
		ul.gform_fields {
			li.gfield {
				margin: 0 0 20px;
				.gfield_label {
					.gfield_required {
						display: none;
					}
				}
				.gfield_description {
					color: #555;
					font-size: 13px;
				}
				.ginput_container {
					&.ginput_container_time {
						&.gfield_time_ampm {
							select {
								width: 100px;
								max-width: none !important;
							}
						}
					}
					&.gfield_time_hour,
					&.gfield_time_minute {
						width: auto;
						max-width: 100%;
						input {
							min-width: 60px;
						}
					}
				}
				&.field_description_above {
					.gfield_label {
						margin-bottom: 0;
					}
					.gfield_description {
						padding: 0;
					}
				}
				&.gf-hidden-label {
					.gfield_label {
						@extend .screen-reader;
					}
				}
				.gfield_radio {
					li {
						input[type=radio] {
							margin: 6px 3px 0 0;
							vertical-align: top;
							&:checked+label {
								font-weight: 300;
							}
						}
						label {
							font-weight: 300;
						}
					}
				}
				.gfield_checkbox {
					li {
						input[type=checkbox] {
							margin: 6px 3px 0 0;
							vertical-align: top;
							&:checked+label {
								font-weight: 300;
							}
						}
						label {
							font-weight: 300;
						}
					}
				}
				&.gsection {
					margin: 40px 0 15px;
					padding: 0;
					border: none;
					.gsection_title {
						margin: 0;
						padding: 0;
						text-align: left;
						text-transform: none;
						font-size: 24px;
						font-weight: 400;
					}
					.gsection_description {
						padding: 0;
					}
				}
				.gform_button_select_files {
					margin: 0 10px;
					background: #CCC;
					font-size: 13px;
				}
				input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
				textarea {
					padding: 0 15px;
					font-size: 16px;
					line-height: 1;
    				min-height: 0;
				}
				textarea {
					padding: 15px;
					&.large {
						height: 195px;
					}
				}
				select {
					padding: 0 40px 0 15px;
				}
				&.gfield_error {
					padding: 0;
					background: none;
					border: none;
					&:not(.gf_left_half):not(.gf_right_half) {
						max-width: 100% !important;
					}
					.ginput_container {
						margin-top: 8px;
					}
					.validation_message {
						padding-top: 5px;
						font-size: 13px;
						color: #790000;
					}
					input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
					textarea {
						border: 2px solid #790000;
					}
				}
			}
		}
	}
	div.gform_footer {
		margin: 20px 0 0;
		padding: 0;
		input[type="submit"].gform_button {
			line-height: 1 !important;
			min-height: 0 !important;
		}
		.gform_ajax_spinner {
			display: inline-block;
			vertical-align: middle;
		}
	}
}

.form-wrapper {
	margin: 60px 0;
	border: 1px solid #ccc;
	padding: 40px 20px;
	div.gform_wrapper {
		margin: 0 auto;
		max-width: 550px;
		.gform_heading h3.gform_title {
			margin-bottom: 30px;
		}
	}
	@include mbp(768) {
		padding: 60px 5%;
	}
	@include mbp(1200) {
		padding: 100px 5%;
	}
}

.protected-post-form {
	margin: 60px 0;
	padding: 60px 20px;
	border: 5px solid $color1;
	background: #FFF;
	.inner {
		max-width: 500px;
		margin: 0 auto 20px;
	}
	h3 {
		font-size: 36px;
	}
	.form-row {
		margin: 0 0 10px;
	}
	&:first-child {
		margin-top: 0;
	}
}

.select2,
.select2-container {
	color: #000;
	ul {
		margin: 0;
		li {
			margin: 0;
		}
	}
}