/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.cta-a {
	padding: 60px 20px 300px;
	background: #FFF url(assets/img/cta.jpg) no-repeat center bottom / 100% auto;
	position: relative;
	.text {
		max-width: 550px;
		font-size: 16px;
	}
	h1, h2, h3, h4, h5, h6 {
		margin: 0 0 20px;
	}
	p {
		margin: 0 0 30px;
	}
	@include mbp(650) {
		padding: 60px 20px 360px;
	}
	@include mbp(768) {
		padding: 60px 5% 470px;
		background-size: cover;
		background-position: center top;
		.text {
			width: column-width(1/2, 5);
			float: right;
		}
		.btn {
			padding: 18px 30px;
			font-size: 20px;
		}
	}
	@include mbp(1200) {
		padding-bottom: 785px;
		.text {
			font-size: 20px;
		}
	}
}
