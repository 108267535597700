/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// TABLES
// ---------------

table {
	max-width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

table.table,
table.wp-block-table {
	width: 100%;
	margin: 0 0 $baseTypeSpacing;
	background: #FFF;
	font-size: 16px;
	th,
	td {
		padding: 20px;
		text-align: left;
		vertical-align: top;
	}
	th {
		font-weight: 700;
	}
	thead {
		th {
			vertical-align: bottom;
			background-color: $textColor;
			color: #FFF;
		}
	}
	tbody {
		tr {
			th,
			td {
				border-bottom: 1px solid #DDD;
			}
			&:nth-child(even) {
				th,
				td {
					background-color: $color4;
				}
			}
		}
	}
	// Remove top border from thead by default
	caption + thead tr:first-child th,
	caption + thead tr:first-child td,
	colgroup + thead tr:first-child th,
	colgroup + thead tr:first-child td,
	thead:first-child tr:first-child th,
	thead:first-child tr:first-child td {
		border-top: 0;
	}
	// Account for multiple tbody instances
	tbody + tbody {
		border-top: 2px solid #DDD;
	}
}
