/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.section-image {
	display: table;
	width: 100%;
	height: 550px;
	background: #000 no-repeat center center / cover;
	.text {
		display: table-cell;
		vertical-align: middle;
		padding: 60px 20px;
		color: #FFF;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
		line-height: 1.2;
		h1, h2, h3, h4, h5, h6 {
			color: #FFF;
		}
	}
	@include mbp(768) {
		.text {
			padding: 60px 5%;
			font-size: 36px;
		}
	}
	@include mbp(1200) {
		.text {
			font-size: 48px;
		}
	}
}
