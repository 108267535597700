.slider-gallery {
	margin: 0 0 60px;
	.slider-for {
		margin-bottom: 10px;
		.slide {
			img {
				width: auto;
				margin: 0 auto;
			}
		}
		.slider-arrow {
			background: rgba(#000, 0.1);
		}
	}
	.slider-nav {
		margin-right: -10px;
		.slick-slide {
			padding-right: 10px;
			img {
				transition: 0.3s ease;
				opacity: 0.8;
				&:hover {
					opacity: 1;
				}
			}
		}
		.slider-arrow.next {
			right: 10px;
		}
	}
}