/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.quick-links-a {
	background: #FFF;
	.quick-link {
		a {
			display: block;
			background: $color1;
			text-decoration: none;
			position: relative;
			transition: 0.5s ease;
			&:hover {
				background: #000;
				img {
					opacity: 0.5;
					mix-blend-mode: normal;
				}
			}
		}
		img {
			width: 100%;
			opacity: 0.5;
			mix-blend-mode: luminosity;
			transition: 0.5s ease;
		}
		.title {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			h3 {
				width: 100%;
				height: 100%;
				display: table;
				margin: 0;
				color: #FFF;
				text-transform: uppercase;
				text-align: center;
				span {
					display: table-cell;
					vertical-align: middle;
					padding: 20px;
				}
			}
		}
	}
	@include mbp(550) {
		@include clearfix;
		.quick-link {
			width: 50%;
			float: left;
			.title {
				h3 {
					font-size: 20px;
				}
			}
		}
	}
	@include mbp(768) {
		.quick-link {
			.title {
				h3 {
					font-size: 30px;
				}
			}
		}
	}
	@include mbp(1100) {
		.quick-link {
			width: 25%;
			.title {
				h3 {
					font-size: 24px;
				}
			}
		}
	}
	@include mbp(1600) {
		.quick-link {
			.title {
				h3 {
					font-size: 30px;
				}
			}
		}
	}
}
