.dlm-downloads {
  list-style: none;
  margin: 60px auto;
  max-width: 680px;
  border-top: 1px solid #EEE;
  li {
    display: block;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #EEE;
    padding-left: 30px;
    &:hover {
      a {
        color: $color2;
      }
    }
    a {
      display: block;
      padding: 20px 20px 20px 20px;
      position: relative;
      color: $textColor;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.woocommerce table.my_account_orders tr td.order-actions,
.woocommerce-page table.my_account_orders tr td.order-actions {
  a {
    margin-top: 5px;
  }
  @include bp(375) {
    a {
      width: 100%;
    }
  }
}

.thankyou-membership {
  margin: 0 0 40px;
  h2 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 15px;
  }
}

.member-logged-in {
  #header-a {
    nav > ul > li {
      &.menu-item-member {
        > a {
          color: $color1;
        }
      }
    }
  }
  @include mbp(768) {
    #header-a {
      nav > ul > li {
        > a {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
  @include mbp(1200) {
    #header-a {
      nav > ul > li {
        margin-left: 15px;
        &.menu-item-member {
          &:hover {
            > a {
              color: $color2;
            }
          }
        }
        > a {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  @include mbp(1300) {
    #header-a {
      nav > ul > li {
        margin-left: 21px;
      }
    }
  }
}

.membership-prompt-message {
  border-radius: 20px;
  padding: 20px;
  background: $color1;
  color: #fff;
  font-weight: 700;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.woocommerce .wc-memberships-content-restricted-message {
  border: none;
  border-radius: 20px;
  padding: 20px;
  background: $color1;
  color: #fff;
  font-weight: 700;
  &:before  {
    display: none;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  .btns {
    margin-top: 10px;
  }
  .btn {
    border-color: #fff;
    text-decoration: none;
  }
}
