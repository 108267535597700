/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.woocommerce,
.woocommerce-page {
	ul.products,
	ul.products[class*=columns-] {
		li.product {
			margin-bottom: 60px;
			position: relative;
			@include column(1, $gutter: 0);
			a {
				&:hover {
					.woocommerce-loop-product__title,
					.woocommerce-loop-category__title {

					}
				}
			}
			.woocommerce-loop-product__title,
			.woocommerce-loop-category__title {

			}
			.price {
				font-size: 20px;
				font-weight: 700;
				color: $color1;
				line-height: 1.2;
				.woocommerce-Price-currencySymbol {
					font-size: 13px;
					vertical-align: super;
				}
				del {
					display: inline;
					color: #777;
					font-size: 0.7em;
					opacity: 1;
				}
				ins {
					text-decoration: none;
				}
			}
			.button {
				@extend .btn;
			}
			@include mbp(480) {
				@include column(1/2, $cycle: 2, $gutter: 5);
			}
			@include mbp(768) {
				@include column(1/3, $cycle: 3, $gutter: 5);
			}
			@include mbp(960) {
				@include column(1/4, $cycle: 4, $gutter: 5);
			}
			.onsale {
				min-width: 0;
				min-height: 0;
				margin: 0;
				padding: 8px 20px;
				line-height: 1;
				font-size: 11px;
				font-weight: 400;
				right: auto;
				left: 0;
				top: 0;
				color: #FFF;
				text-transform: uppercase;
				background: darkred;
				border-radius: 0;
			}
			.post-meta {
				time {
					display: block;
					width: 80px;
					height: 80px;
					padding-top: 15px;
					border-radius: 100%;
					background: $color1;
					color: #FFF;
					text-align: center;
					font-weight: 700;
					line-height: 1;
					position: absolute;
					top: -15px;
					left: -15px;
					.day {
						width: 100%;
						margin: 0 0 5px;
						font-size: 30px;
						line-height: 0.8;
					}
					.month-year {
						width: 100%;
						font-size: 13px;
					}
				}
			}
		}
	}
}

.woocommerce {
	a.button,
	button.button,
	input.button,
	button.button.alt,
	input.button.alt {
		@extend .btn;
		&:disabled,
		&:disabled[disabled],
		&.disabled {
			@extend .btn;
		}
	}
	table.shop_table {
		border: none;
		font-size: 16px;
		thead {
			th {
				text-transform: uppercase;
				font-family: $secondaryFontFamily;
				font-weight: 700;
			}
		}
		tbody {
			td {
				input.button,
				button,
				.btn {
					height: 30px;
					line-height: 27px;
					padding: 0 20px;
					font-size: 11px !important;
				}
				input[type="text"],
				input[type="number"] {
					height: 30px;
					padding: 0 10px;
					background: $color4;
					border: none;
				}
				&.product-remove {
					.remove {
						width: 25px;
						height: 25px;
						line-height: 25px;
						color: $color1 !important;
						font-weight: 300;
						&:hover {
							background: $color1;
							color: #FFF !important;
						}
					}
				}
				&.actions {
					padding: 10px 0;
				}
				#coupon_code {
					border: none;
					background: #EEE;
					font-size: 13px !important;
					@include mbp(769) {
						width: 150px;
						float: none;
					}
				}
			}
		}
		tfoot {
			th,
			td {

			}
		}
	}
	nav.woocommerce-pagination {
		ul.page-numbers {
			border: none;
			li {
				margin: 0 5px;
				border: none;
				.page-numbers {
					width: 30px;
					height: 30px;
					margin: 0;
					padding: 0;
					line-height: 28px;
					display: block;
					border: 1px solid $color2;
					border-radius: 100%;
					color: $color2;
					font-weight: 400;
					font-size: 13px;
					&:hover {
						border-color: $color1;
						background: $color1;
						color: #FFF;
					}
				}
				span.current {
					border-color: $color1;
					background: $color1;
					color: #FFF;
				}
			}
		}
	}
	.widget_price_filter {
		.price_slider_wrapper {
			.ui-widget-content {
				background-color: #EEE;
			}
		}
		.ui-slider {
			.ui-slider-handle {
				background-color: #EEE;
				border: 1px solid $color1;
			}
		}
		.ui-slider-horizontal {
			.ui-slider-range {
				background-color: $color1;
			}
		}
	}
	.widget_layered_nav_filters {
		ul {
			font-size: 13px;
			li {
				margin-right: 10px;
			}
		}
	}
}

.woocommerce.single-product {
	div.product {
		.product_title {
			font-size: 36px;
		}
		.post-meta {
			margin: 0 0 20px;
			color: $color4;
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 1px;
			@include clearfix;
			a {
				color: $color1;
				text-decoration: none;
				&:hover {
					color: $color2;
				}
			}
			time {
				text-transform: none;
				display: block;
				width: 100px;
				height: 100px;
				padding-top: 25px;
				border-radius: 100%;
				background: $color1;
				color: #FFF;
				text-align: center;
				font-weight: 700;
				line-height: 1;
				position: absolute;
				top: -25px;
				left: -25px;
				.day {
					width: 100%;
					margin: 0 0 5px;
					font-size: 30px;
					line-height: 0.8;
				}
				.month-year {
					width: 100%;
					font-size: 13px;
				}
			}
		}
		div.images {
			margin-bottom: 60px;
			figure {
				margin: 0;
			}
			.flex-viewport {
				margin-bottom: 2%;
				border: 1px solid $hrColor;
			}
			.flex-control-thumbs {
				@include clearfix;
				li {
					margin-bottom: 2%;
					@include column(1/4, $cycle: 4, $gutter: 2);
					img {
						opacity: 1;
						border: 1px solid $hrColor;
						transition: 0.5s ease;
					}
					&:hover {
						img {
							border-color: $color1;
						}
					}
				}
			}
			@include mbp(768) {
				width: percentage(550/1200);
				margin-bottom: 100px;
			}
		}
		div.summary {
			margin-bottom: 60px;
			.woocommerce-product-rating {
				.star-rating {
					display: inline-block;
					vertical-align: middle;
					margin: 0 5px 5px 0;
					float: none;
					font-size: 24px;
					&:before {
						color: #f2b100;
					}
					span {
						&:before {
							color: #f2b100;
						}
					}
				}
				.woocommerce-review-link {
					display: inline-block;
					vertical-align: middle;
					margin-bottom: 5px;
					font-size: 13px;
				}
			}
			p.price {
				color: $color1;
				font-weight: 700;
				font-size: 24px;
				.woocommerce-Price-currencySymbol {
					font-size: 13px;
					vertical-align: super;
				}
				del {
					display: inline;
					color: #777;
					font-size: 0.7em;
				}
				ins {
					color: $color1;
					font-size: 30px;
					text-decoration: none;
				}
			}
			table.variations {
				td {
					padding: 10px 0;
					&.label {
						width: 120px;
						label {
							font-size: 16px;
						}
					}
				}
			}
			.single_variation_wrap {
				@include clearfix;
				.single_variation {
					margin: 0 0 20px;
				}
			}
			.quantity {
				margin: 0 10px 20px 0;
				.qty {
					width: 100px;
					height: 50px;
					padding: 0 15px;
				}
			}
			.single_add_to_cart_button {
				@extend .btn;
				height: 50px;
				line-height: 50px;
				padding: 0 30px;
				font-size: 20px;
			}
			@include mbp(768) {
				width: percentage(550/1200);
				margin-bottom: 100px;
			}
		}
		.onsale {
			min-width: 0;
			min-height: 0;
			margin: 0;
			padding: 8px 20px;
			line-height: 1;
			font-size: 11px;
			font-weight: 400;
			right: auto;
			left: 0;
			top: 0;
			color: #FFF;
			text-transform: uppercase;
			background: darkred;
			border-radius: 0;
		}
		.related,
		.upsells {
			clear: both;
			> h2 {
				margin: 0 0 60px;
				text-align: center;
			}
		}
	}
}

.woocommerce-cart {
	table.shop_table.cart {
		.cart_item {
			a {
				text-decoration: none;
			}
			.variation {
				font-size: 13px;
				line-height: 1.2;
				@include clearfix;
				dt, dd {
					margin: 0 0 5px;
					padding: 0;
					display: block;
				}
				dt {
					width: 120px;
					margin: 0 10px 5px 0;
					float: left;
					clear: left;
					font-weight: 700;
					text-align: left;
				}
				dd {
					margin: 0 0 10px;
					float: left;
				}
			}
		}
		.actions {
			text-align: right;
		}
	}
	.cart-collaterals {
		.cart_totals {
			h2 {
				text-transform: uppercase;
				font-size: 20px;
			}
			table {
				width: 100%;
				text-align: left;
				font-size: 16px;
				th,
				td {
					padding: 10px;
				}
			}
			.wc-proceed-to-checkout {
				.checkout-button {
					@extend .btn;
					height: 50px;
					line-height: 47px;
					padding: 0 30px;
					font-size: 20px;
				}
			}
		}
	}
}

.woocommerce-checkout {
	.checkout {
		font-size: 16px;
		h3 {
			text-transform: uppercase;
			font-size: 20px;
		}
	}
	.woocommerce-checkout-review-order-table {
		@extend .table;
		tbody {
			tr {
				td {
					padding: 20px;
					border-top: none;
				}
			}
		}
		td.product-name {
			.variation {
				font-size: 13px;
				line-height: 1.2;
				@include clearfix;
				dt, dd {
					margin: 0 0 5px;
					padding: 0;
					display: block;
				}
				dt {
					width: 120px;
					margin: 0 10px 5px 0;
					float: left;
					clear: left;
					font-weight: 700;
					text-align: left;
				}
				dd {
					margin: 0 0 10px;
					float: left;
				}
			}
		}
	}
	#payment {
		background: $color4;
		label {
			display: inline-block;
		}
		img {
			display: inline-block;
		}
		ul.payment_methods {
			border-bottom: 1px solid #DDD;
		}
		#place_order {
			@extend .btn;
			height: 50px;
			line-height: 47px;
			padding: 0 30px;
			font-size: 20px;
		}
	}
}

#customer_login {
	h2 {
		font-size: 30px;
	}
	form.register,
	form.login {
		border-radius: 0;
	}
	input[type="submit"] {
		margin-right: 10px;
	}
	.lost_password {
		font-size: 11px;
	}
}

.woocommerce-account {
	.woocommerce-MyAccount-navigation {
		margin: 0 0 60px;
		display: none;
		ul {
			margin: 0;
			text-transform: uppercase;
			font-weight: 700;
			li {
				display: block;
				margin: 0 0 5px;
				a {
					display: block;
					padding: 10px 20px;
					background: $color4;
					color: $color2;
					text-decoration: none;
					&:hover {
						color: $color1;
					}
				}
			}
		}
		@include mbp(768) {
			width: column-width(1/3, 5);
			margin-right: 5%;
			float: left;
		}
		@include mbp(1200) {
			width: column-width(1/4, 5);
		}
	}
	.woocommerce-MyAccount-content {
		width: 100%;
		float: none;
	}
}

.woocommerce {
	#reviews {
		width: 100%;
		clear: both;
		#comments {
			.woocommerce-Reviews-title {
				margin: 0 0 45px;
			}
			.woocommerce-noreviews {

			}
			ol.commentlist {
				border-top: 1px solid $textColor;
				li {
					padding: 70px 20px 30px;
					border: none;
					position: relative;
					border-bottom: 1px solid $textColor;
					.comment_container {
						.avatar {
							display: none;
						}
						.comment-text {
							margin: 0;
							padding: 0;
							background: none;
							border: none;

							.meta {
								font-family: $secondaryFontFamily;
								.woocommerce-review__author {
									display: block;
									font-size: 24px;
									font-weight: 700;
									color: $textColor;
								}
								.woocommerce-review__dash {
									display: none;
								}
								.woocommerce-review__published-date {
									display: block;
									font-size: 16px;
									font-weight: 400;
									color: #999;
								}
							}
							.star-rating {
								position: absolute;
								top: 30px;
								left: 20px;
								font-size: 24px;
								color: #f2b100;
								&:before {
									color: #f2b100;
								}
								span {
									color: #f2b100;
									&:before {
										color: #f2b100;
									}
								}
							}
							.description {
								font-family: $secondaryFontFamily;
								font-style: italic;
								font-size: 20px;
								font-weight: 400;
							}
						}
					}
					@include mbp(768) {
						padding: 30px 20px;
						.comment_container {
							.comment-text {
								@include clearfix;
								.meta {
									width: column-width(1/3, 5);
									padding-top: 40px;
									float: left;
								}
								.description {
									width: column-width(2/3, 5);
									float: right;
									font-size: 24px;
									line-height: 1.4;
									p {
										&:last-child {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
					@include mbp(1200) {
						padding: 50px;
						.comment_container {
							.comment-text {
								.meta {
									width: column-width(1/5, 5);
								}
								.star-rating {
									top: 50px;
									left: 50px;
								}
								.description {
									width: column-width(4/5, 5);
								}
							}
						}
					}
				}
			}
		}
		#review_form_wrapper {
			padding-top: 60px;
			.comment-respond {
				max-width: 600px;
				margin: 0 auto;
				#reply-title {
					@extend h2;
					display: block;
				}
				p {
					margin: 0 0 20px;
				}
			}
			label {
				font-family: $secondaryFontFamily;
				font-size: 24px;
			}
			#{$all-text-inputs},
			select[multiple=multiple],
			select {
				border-color: $textColor;
			}
			textarea {
				display: block;
			}
			input[type="submit"] {
				@extend .btn;
			}
			.stars {
				a {
					color: #f2b100;
					font-size: 24px;
				}
			}
			@include mbp(768) {
				input[type="submit"] {
					min-width: 200px;
					padding: 15px 20px;
					font-size: 24px;
				}
			}
		}
	}
}

// ONE PAGE CHECKOUT
.subscription-single {
	h1 {
		margin-bottom: 30px;
	}
	.attention {
		color: $textColor;
		margin: 20px 0 40px;
	}
	.total-desc {
		margin: 20px 0 0;
		color: $color1;
	}
	// .woocommerce-Price-currencySymbol {
	// 	&::before {
	// 		content: 'USD ';
	// 	}
	// }
	#checkout-products {
		list-style: none;
		display:flex;
		justify-content: space-between;
		margin: 0 0 20px;
		.product-item {
			width: 32%;
			border-radius: 20px;
			border: 2px solid #ccc;
			padding: 27px 20px 20px 40px;
			font-size: 15px;
			font-weight: 500;
			position: relative;
			&::before {
				content: 'Save 5%';
				background: $color3;
				position: absolute;
				top: -2px;
				right: -2px;
				font-size: 12px;
				padding: 2px 5px;
				border-top-right-radius: 20px;
			}
			&:last-child {
				&::before {
					content: 'Save 10%';
				}
			}
			&:first-child {
				&::before {
					display: none;
				}
			}
			&.selected {
				border-color: $color1;
				background: $color1;
			}
			&:only-child {
				width: 100%;
				&::before {
					display: none;
				}
			}
			label {
				display: inline-block;
				margin-right: 5px;
				position: absolute;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
				input {
					position: relative;
					top: 24px;
					left: 20px;
				}
			}
			.attributes {
				line-height: 17px;
				display: inline-block;
				font-weight: 600;
			}
			.dash {
				display: none;
			}
			.price {
				font-weight: 400;
				display: block;
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
	.woocommerce-message {
		display: none;
	}
	.opc_order_review .product-quantity {
		display: none;
	}
	@include mbp(1000) {
		> h1,
		> p {
			text-align: center;
		}
		#checkout-products {
			.product-item {
				&:first-child {
					&::before {
						padding: 5px 10px;
						font-size: 13px;
					}
				}
			}
		}
	}
	@include mbp(768) {
		#checkout-products {
			.product-item {
				padding-top: 20px;
				&::before {
					padding: 5px 10px;
				}
				label {
					input {
						top: 20px;
					}
				}
			}
		}
	}
	@include mbp(1200) {
		#checkout-products {
			.product-item {
				label {
					input {
						top: 18px;
					}
				}
				.attributes {
					font-size: 18px;
				}
			}
		}
	}
	@include mbp(1400) {
		.page-title {
			font-size: 40px;
		}
	}
}

.custom-login-form {
	h2 {
		font-size: 48px;
		text-align: center;
	}
	.woocommerce form.login {
		border: 2px solid $color1;
	}
	@include mbp(768) {
		.woocommerce form.login {
			padding: 40px;
		}
	}
}

// fix display issue where add to cart on product single is overlapping ticket info
.wc-box-office-ticket-form {
	clear: both;
}

.woocommerce-info{
	border-color: #000;
}

// Set checkout custome details to be one column
.woocommerce #customer_details {
	.col-1 {
		float: none;
		width: 100%;
	}
	.col-2 {
		float: none;
		width: 100%;
		.woocommerce-additional-fields {
			padding-top: 20px;
		}
	}
	@include bp(630) {
		.form-row {
			float: none;
			width: 100%
		}
	}
}

.woocommerce-Price-currencySymbol {
	&::before {
		content: 'AUD ';
	}
}

.woocommerce ul.products li.product .onsale,
.woocommerce ul.products[class*=columns-] li.product .onsale,
.woocommerce-page ul.products li.product .onsale,
.woocommerce-page ul.products[class*=columns-] li.product .onsale {
	display: none;
}

.woocommerce.single-product div.product .onsale {
	display: none;
}

.woocommerce-error, .woocommerce-info, .woocommerce-message {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 5em;
}

// My Memberships page, hide actions column
.membership-actions.order-actions {
	display: none;
}
