/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import "../../node_modules/magnific-popup/src/css/main";

div.mfp-bg.rpjc-magnific {
	background: rgba(#000, 0.9);
	opacity: 1;
}

div.rpjc-magnific {
	.mfp-container {
		padding-top: 80px;
	}
	.mfp-close {
		width: 80px;
		height: 80px;
		padding: 0;
		margin: 0;
		background: none;
		opacity: 1;
		top: 0;
		right: 0;
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		span {
			width: 40px;
			height: 1px;
			display: block;
			margin-top: 20px;
			margin-left: 20px;
			background: #FFF;
			transition: all 0.3s ease-in-out;
			&:first-child {
				transform: translateY(21px) rotate(-45deg);
			}
			&:last-child {
				transform: rotate(45deg);
			}
		}
		&:hover {
			span {
				background: $color1;
			}
		}
	}
	.mfp-title {
		padding: 10px 40px 10px 0;
		font-size: 16px;
	}
	.mfp-counter {
		padding: 10px 0;
	}
	.mfp-arrow {
		width: auto;
		height: 50px;
		margin-top: -25px;
		cursor: pointer;
		opacity: 1;
		z-index: 1046;
		transform: scale(1);
		span {
			width: 30px;
			height: 1px;
			display: block;
			background: #FFF;
			position: relative;
			top: 10px;
			transition: all 0.3s ease-in-out;
		}
		&.mfp-arrow-left {
			left: 20px;
			span {
				&:first-child {
					transform: translateY(22px) rotate(45deg);
				}
				&:last-child {
					transform: rotate(-45deg);
				}
			}
		}
		&.mfp-arrow-right {
			right: 20px;
			span {
				&:first-child {
					transform: translateY(22px) rotate(-45deg);
				}
				&:last-child {
					transform: rotate(45deg);
				}
			}
		}
		&:before,
		&:after {
			display: none;
		}
		&:hover {
			span {
				background: $color1;
			}
		}
	}
	&.rpjc-magnific-team {
		background: #FFF;
		.mfp-close {
			span {
				background: $color1;
			}
			&:hover {
				span {
					background: $color2;
				}
			}
		}
		.mfp-content {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
