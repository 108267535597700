/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

#header-a {
	width: 100%;
	position: relative;
	z-index: 500;
	padding: 80px 0 0;
	background: #FFF;
	.container {
		max-width: 1500px;
	}
	.logo {
		margin: 0 20px 20px;
		img {
			margin: 0 auto;
		}
		.white,
		.alt {
			display: none;
		}
	}
	.header-buttons {
		margin: 0 20px 30px;
		font-size: 0;
		text-align: center;
		ul {
			margin: 0;
			li {
				display: block;
				margin: 0 0 5px;
				a {
					@extend .btn;
					display: block;
					font-size: 13px;
				}
				ul {
					display: none;
				}
			}
		}
		@include mbp(480) {
			ul {
				li {
					display: inline-block;
					margin: 0 5px 5px;
				}
			}
		}
	}
	.social-icons {
		position: absolute;
		top: 11px;
		right: 20px;
		z-index: 2;
		line-height: 1;
		a {
			margin: 0 0 0 10px;
			color: $textColor;
			font-size: 16px;
			transition: color 0.2s ease;
			&:hover {
				color: $color2;
			}
		}
	}
	nav {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $color4;
		.menu-collapser {
			@include clearfix;
			.collapse-button {
				padding: 12px 20px;
				float: left;
				cursor: pointer;
				.hamburger {
					width: 22px;
					span {
						display: block;
						height: 3px;
						margin-top: 4px;
						background: $textColor;
						transition: all 0.15s ease-in-out;
						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
			&.open {
				.collapse-button {
					.hamburger {
						span {
							opacity: 0;
							&:first-child {
								opacity: 1;
								transform: translateY(7px) rotate(-45deg);
							}
							&:last-child {
								opacity: 1;
								transform: translateY(-7px) rotate(45deg);
							}
						}
					}
				}
			}
		}
		ul {
			margin: 0;
			list-style: none;
			font-size: 14px;
			font-family: $secondaryFontFamily;
			font-weight: 700;
			line-height: 1.2;
			li {
				display: block;
				margin: 0;
				position: relative;
				a {
					display: block;
					padding: 12px 50px 12px 20px;
					color: $textColor;
					&[href="#"] {
						cursor: default;
					}
				}
				ul {
					display: none;
					background: $color2;
					li {
						a {
							color: #FFF;
						}
						ul {
							background: darken($color2, 5%);
						}
						.sub-collapser,
						.sub-toggle {
							background: darken($color2, 5%);
						}
					}
				}
				.sub-collapser,
				.sub-toggle {
					width: 48px;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					text-align: center;
					z-index: 999;
					cursor: pointer;
					background: $color2;
					font-size: 20px;
					> i {
						color: #FFF;
						line-height: 42px;
					}
					&:before {
						content: '';
						display: inline-block;
						height: 100%;
						vertical-align: middle;
					}
					&.expanded {
						height: 42px;
					}
				}
			}
		}
	}
	@include mbp(768) {
		padding-top: 30px;
		.inner {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 0 30px;
			padding: 0 5%;
		}
		.logo {
			margin: 0;
			float: left;
			img {
				margin: 0;
			}
		}
		.header-r {
			margin: 0 0 0 20px;
			padding: 10px 0 0;
			float: right;
			text-align: right;
		}
		.header-buttons {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 10px 10px;
			ul {
				li {
					margin: 0 0 0 10px;
				}
			}
		}
		.social-icons {
			display: inline-block;
			vertical-align: middle;
			margin: 0 0 10px;
			position: static;
			top: auto;
			right: auto;
			a {
				color: $color1;
				font-size: 20px;
				&:hover {
					color: $color2;
				}
			}
		}
		nav {
			clear: both;
			position: static;
			top: auto;
			left: auto;
			padding: 0 5%;
			.menu-collapser,
			.sub-collapser,
			.sub-toggle {
				display: none;
			}
			ul {
				font-size: 13px;
				text-align: center;
				li {
					display: inline-block;
					vertical-align: top;
					a {
						padding: 15px;
						text-decoration: none;
						&:hover {
							background: $color2;
							color: #FFF;
						}
					}
					ul {
						width: 160px;
						margin: 0;
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 1;
						background: $color4;
						text-align: left;
						li {
							display: block;
							border-top: 1px solid rgba(#000, 0.1);
							a {
								padding: 10px 15px;
								color: $textColor;
								&:hover {
									background: $color2;
									color: #FFF;
								}
							}
							ul {
								top: 0;
								left: 100%;
								z-index: 2;
								background: $color4;
								box-shadow: inset 1px 0 9px -5px rgba(#000, 0.3);
							}
							&:first-child {
								border: none;
							}
							&:hover,
							&.current-menu-ancestor,
							&.current-menu-item {
								> a {
									background: $color2;
									color: #FFF;
								}
							}
						}
					}
					&:hover,
					&.current-menu-ancestor,
					&.current-menu-item {
						> a {
							background: $color2;
							color: #FFF;
						}
					}
				}
			}
		}
	}
	@include mbp(1200) {
		height: 150px;
		padding: 30px 5% 0;
		.inner {
			display: block;
			margin: 0;
			padding: 0;
		}
		.logo {
			margin: 0;
		}
		.header-r {
			margin: 0;
			padding-top: 0;
		}
		.header-buttons {
			margin: 0 0 30px 10px;
			padding: 0;
			ul {
				li {
					a {
						font-size: 16px;
					}
				}
			}
		}
		.social-icons {
			margin-bottom: 30px;
			padding: 0;
			a {
				margin-left: 15px;
			}
		}
		nav {
			width: auto;
			background: none;
			float: right;
			clear: right;
			padding: 0;
			ul {
				margin: 0;
				font-size: 16px;
				li {
					margin-left: 20px;
					padding-bottom: 10px;
					a {
						padding: 0;
						color: $textColor;
						&:hover {
							color: $color2;
							background: none;
						}
					}
					ul {
						width: 195px;
						font-size: 13px;
						li {
							margin: 0;
							padding: 0;
						}
					}
					&:hover,
					&.current-menu-ancestor,
					&.current-menu-item {
						> a {
							color: $color2;
							background: none;
						}
					}
				}
			}
		}
		&.stuck {
			animation: fadeInDown 0.3s ease forwards;
			height: 100px;
			padding-top: 0;
			position: fixed;
			top: 0;
			left: 0;
			box-shadow: 0 0 10px rgba(#000, 0.15);
			border: none !important;
			.logo {
				width: 250px;
				padding-top: 28px;
				.default {
					display: none;
				}
				.alt {
					display: block;
				}
			}
			.header-buttons {
				margin-bottom: 20px;
				ul {
					li {
						a {
							border-top: none;
							border-radius: 0 0 20px 20px;
							font-size: 13px;
						}
					}
				}
			}
			.social-icons {
				margin-bottom: 20px;
				padding-top: 8px;
				a {
					margin-left: 10px;
					font-size: 16px;
				}
			}
		}
	}
}

.wrapper-header-a {
	&.header-stuck {
		@include mbp(1200) {
			padding-top: 150px;
		}
	}
}

body.admin-bar {
	#header-a {
		@include mbp(1200) {
			&.stuck {
				top: 32px;
			}
		}
	}
	&.page-template-template-home {
		#header-a {
			@include mbp(1200) {
				top: 32px;
			}
		}
	}
}

body.page-template-template-home {
	.wrapper-header-a {
		@include mbp(1200) {
			padding-top: 0 !important;
		}
	}
	#header-a:not(.stuck) {
		@include mbp(1200) {
			position: absolute;
			top: 0;
			left: 0;
			background: none;
			border: none;
			.logo {
				.default {
					display: none;
				}
				.white {
					display: block;	
				}
			}
			nav {
				ul {
					li {
						a {
							color: #FFF;
						}
						ul {
							li {
								a {
									color: $textColor;
									&:hover {
										color: #FFF;
									}
								}
							}
						}
						&:hover,
						&.current-menu-ancestor,
						&.current-menu-item {
							> a {
								color: $color2;
							}
						}
					}
				}
			}
		}
	}
}

body {
	&.single-post,
	&.single-event {
		#header-a {
			&:not(.stuck) {
				border-bottom: 1px solid #EEE;
			}
		}
	}
}