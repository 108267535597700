/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import "footer";

#footer-a {
	padding: 60px 20px 0;
	background: #FFF;
	border-top: 1px solid #EEE;
	font-size: 16px;
	font-weight: 400;
	.widget {
		margin-bottom: 30px;
	}
	nav {
		ul {
			margin: 0;
			font-weight: 700;
			li {
				display: block;
				margin: 0 0 35px;
				ul {
					font-size: 13px;
					li {
						margin: 0;
						padding: 10px 0 0;
					}
				}
			}
		}
	}
	.social-icons {
		margin: 0 0 30px;
		font-size: 20px;
		a {
			margin: 0 15px 5px 0;
		}
	}
	.footer-r {
		margin-bottom: 60px;
		img {
			margin-bottom: 20px;
		}
	}
	.copyright {
		font-size: 11px;
		span {
			padding: 0 10px;
		}
	}
	@include mbp(480) {
		.widgets {
			@include clearfix;
			.widget {
				@include column(1/2, $cycle: 2, $gutter: 5);
			}
		}
	}
	@include mbp(768) {
		padding: 60px 5% 0;
		.widgets {
			.widget {
				@include column(1/4, $cycle: 4, $gutter: 5);
			}
		}
	}
	@include mbp(960) {
		padding: 90px 5% 30px;
		.widgets {
			width: percentage(940/1500);
			float: left;
		}
		.footer-r {
			width: percentage(300/1500);
			float: right;
			text-align: right;
			@include clearfix;
			img {
				margin-bottom: 80px;
				float: right;
			}
			.copyright {
				clear: both;
			}
		}
	}
}
