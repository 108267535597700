/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.footer {
	width: 100%;
	position: relative;
	clear: both;
	a {
		color: $textColor;
		text-decoration: none;
		&:hover {
			color: $color2;
		}
	}
	a[href="#"]{
		color: $textColor;
		cursor: default;
	}
	.container {
		max-width: 1500px;
	}
	.widget-title {
		font-size: 16px;
		color: $textColor;
	}
	ul.contact-details {
		margin: 0 0 $baseTypeSpacing;
		line-height: 1.2;
		li {
			display: block;
			margin: 0 0 20px;
			font-size: 13px;
			.label {
				width: 70px;
				display: inline-block;
				vertical-align: top;
				margin-right: 10px;
				font-weight: 400;
			}
			.value {
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}
