/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.accordion-a {
	margin: 40px 0 60px;
	clear: both;
	border-bottom: 1px solid $hrColor;
	.accordion-item {
		border-top: 1px solid $hrColor;
		.accordion-handle {
			cursor: pointer;
			position: relative;
			transition: all 0.3s ease;
			h3 {
				padding: 15px 50px 15px 20px;
				margin: 0;
				font-size: 16px;
				font-family: $headingsFontFamily;
				font-weight: 700;
				color: $textColor;
				position: relative;
				&:after {
					font-family: FontAwesome;
					content: '\f107';
					font-size: 20px;
					position: absolute;
					right: 20px;
					top: 12px;
					color: $color1;
					transition: all 0.3s ease;
				}
			}
		}
		.accordion-content {
			padding: 30px 20px;
			font-size: 16px;
			@include clearfix;
		}
		&.selected {
			border-top: none;
			background-color: $color4;
			.accordion-handle {
				background: $color1;
				h3 {
					color: #FFF;
					&:after {
						transform: rotate(180deg);
						color: #FFF;
					}
				}
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
	@include mbp(768) {
		.accordion-item {
			.accordion-handle {
				h3 {
					padding: 25px 60px 25px 30px;
					&:after {
						top: 23px;
						right: 30px;
					}
				}
			}
			.accordion-content {
				padding: 45px 50px 30px;
			}
		}
	}
}
