/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.site-search {
	width: 100%;
	padding: 60px 5%;
	position: fixed;
	top: 0;
	bottom: 0;
	left: -100%;
	z-index: 1000;
	opacity: 0;
	background: rgba(#000, 0.8);
	transition: opacity 0.5s ease;
	.container {
		height: 100%;
		position: relative;
	}
	h3 {
		text-align: center;
		color: #FFF;
	}
	.searchform {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		form {
			width: 500px;
			max-width: 100%;
			margin: 0 auto;
			background: #FFF;
		}
		input {
			height: 60px;
			padding-left: 25px;
		}
		button {
			width: 60px;
			height: 60px;
			right: 0;
			font-size: 24px;
		}
	}
	.site-search-close {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		span {
			width: 40px;
			height: 1px;
			display: block;
			margin-top: 20px;
			margin-left: 20px;
			background: #FFF;
			transition: 0.2s ease;
			&:first-child {
				@include transform(translateY(21px) rotate(-45deg));
			}
			&:last-child {
				@include transform(rotate(45deg));
			}
		}
		&:hover {
			span {
				background: $color1;
			}
		}
	}
}
body {
	&.search-open {
		overflow: hidden;
		.site-search {
			left: 0;
			opacity: 1;
		}
	}
}

div.search-results {
	.search-result {
		margin: 0 0 30px;
		padding: 0 0 30px;
		border-bottom: 1px solid $hrColor;
		h4 {
			margin: 0 0 10px;
			a, a:hover {
				color: $color1;
				text-decoration: none;
			}
		}
		p {
			margin: 0 0 10px;
		}
		.read-more {
			font-size: 13px;
			font-style: italic;
		}
		.url {
			font-size: 13px;
			a {
				color: green;
				&:hover {
					color: green;
					text-decoration: underline;
				}
			}
		}
		&:first-child {
			padding-top: 30px;
			border-top: 1px solid $hrColor;
		}
	}
}
