/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.post-share {
	margin: 40px 0;
	ul {
		margin: 0;
		line-height: 1;
		font-size: 13px;
		font-weight: 700;
		text-align: center;
		@include clearfix;
		li {
			display: inline-block;
			margin: 0 10px 10px;
			a {
				display: block;
				color: $textColor;
				.fa {
					margin-right: 5px;
					font-weight: normal;
					font-size: 16px;
					color: $color2;
				}
				&:hover {
					color: $color2;
					text-decoration: none;
				}
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
}
