/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@keyframes pace-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeInRightBig {
	from {
		opacity: 0;
		transform: translate3d(2000px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(50px, 0, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -50px, 0);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-30px);
	}
	60% {
		transform: translateY(-15px);
	}
}

@keyframes quicklinksCFadeInUp {
	from {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	to {
		opacity: 1;
		transform: none;
		height: auto;
	}
}
