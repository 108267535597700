/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

 .hero-a {
	display: table;
	width: 100%;
	height: 250px;
	clear: both;
	position: relative;
	.inner {
		display: table-cell;
		vertical-align: bottom;
		padding: 20px 20px 40px;
		position: relative;
		z-index: 2;
	}
	.container {
		max-width: 1500px;
	}
	h1 {
		margin: 0;
		font-size: 36px;
		text-align: left;
		position: relative;
		z-index: 2;
		small {
			display: block;
			font-size: 0.5em;
		}
	}
	.image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		background: no-repeat center center / cover;
	}
	@include mbp(768) {
		.inner {
			padding: 20px 5% 60px;
		}
		h1 {
			font-size: 48px;
		}
	}
	@include mbp(1500) {
		height: 450px;
		.inner {
			padding-bottom: 90px;
		}
		h1 {
			font-size: 60px;
		}
	}
}
.cssanimations {
	.hero-a {
		h1 {
			opacity: 0;
			animation: fadeInUp 0.5s 1s forwards;
		}
	}
}
