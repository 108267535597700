/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

 .team {
	&.team-b {
		margin: 40px 0 0;
		.person {
			max-width: 480px;
			display: block;
			padding-bottom: 60px;
		}
		.team-link {
			display: block;
			text-decoration: none;
			color: $textColor;
			position: relative;
			&:hover {
				.photo {
					img {
						filter: grayscale(0);
					}
				}
				h3 {
					color: $color1;
				}
			}
			&.active {
				.photo {
					img {
						filter: grayscale(0);
					}
				}
				h3 {
					color: $color1;
				}
				&:after {
					opacity: 1;
					bottom: -60px;
				}
			}
			&:after {
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 50px 50px 50px;
				border-color: transparent transparent $color1 transparent;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				opacity: 0;
			}
		}
		.photo {
			margin: 0 0 20px;
			img {
				width: 100%;
				filter: grayscale(1);
				transition: 0.3s ease;
			}
		}
		h3 {
			margin: 0 0 10px;
			font-size: 24px;
			color: $color1;
			transition: 0.3s ease;
		}
		.position {
			font-size: 13px;
			font-weight: 700;
			text-transform: uppercase;
			color: #999;
		}
		.person-details {
			width: 100%;
			clear: both;
			margin: 0 0 60px;
			background: #FFF;
			border: 2px solid $color1;
			position: relative;
			.inner {
				padding: 90px 30px 50px;
			}
			.close {
				width: 40px;
				height: 40px;
				position: absolute;
				top: 30px;
				right: 30px;
				cursor: pointer;
				transition: all 0.3s ease-in-out;
				span {
					width: 40px;
					height: 1px;
					display: block;
					background: $color1;
					transition: all 0.3s ease-in-out;
					&:first-child {
						transform: translateY(21px) rotate(-45deg);
					}
					&:last-child {
						transform: translateY(21px) rotate(45deg);
					}
				}
				&:hover {
					span {
						background: $color1;
					}
				}
			}
		}
		&:first-child {
			margin-top: 0;
		}
		@include mbp(768) {
			.row {
				width: 100%;
				display: flex;
				align-items: stretch;
				flex-wrap: wrap;
			}
			.person {
				max-width: 100%;
				@include column(1/3, $cycle: 3, $gutter: 5);
				.team-link {
					height: 100%;
				}
			}
		}
		@include mbp(1200) {
			.person-details {
				.inner {
					padding: 90px 50px 50px;
				}
			}
		}
	}
}
