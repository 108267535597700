/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.sidebar-a {
	.widget {
		margin-bottom: 60px;
		padding: 30px;
		background: $color4;
		.widget-title {
			margin: 0 0 20px;
			font-size: 20px;
			color: $textColor;
			a {
				color: $textColor;
				text-decoration: none;
			}
		}
	}
	.widget_nav_menu,
	.widget_pages,
	.widget_categories,
	.widget_recent_entries,
	.widget_meta,
	.widget_archive,
	.widget_product_categories,
	.widget_recent_comments {
		ul {
			margin: 0;
			line-height: 1.2;
			font-size: 16px;
			font-weight: 700;
			li {
				display: block;
				margin: 10px 0 0;
				a {
					color: $textColor;
					&:hover {
						color: $color2;
						text-decoration: none;
					}
				}
				ul {
					font-size: 13px;
				}
				&.current_page_item,
				&.current-cat,
				&.current_page_ancestor {
					> a {
						color: $color2;
					}
				}
			}
		}
	}
	.widget_recent_entries {
		ul {
			li {
				margin: 0 0 20px;
				.post-date {
					display: block;
					font-size: 0.8em;
					color: #AAA;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	@include mbp(1200) {
		.widget {
			padding: 40px;
		}
	}
}
