/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// IMAGES
// ---------------

.alignright,
.alignleft,
.aligncenter,
.alignnone {
	margin: 60px 0;
	&:first-child {
		margin-top: 0;
	}
}
.aligncenter {
	margin: 60px auto;
}
@include mbp(768) {
	.alignright {
		float: right;
		margin: 0 0 60px 60px;
	}
	.alignleft {
		float: left;
		margin: 0 60px 60px 0;
	}
	.aligncenter {
		margin: 60px auto;
	}
}

.wp-caption {
	width: auto !important;
	text-align: center;
}

.wp-caption-text {
	color: #999;
	font-weight: 700;
	margin: 0;
	padding: 10px 0 0;
	font-size: 13px;
}

.gallery-caption {}

// COLUMNS
// ---------------

.row {
	.one-half,
	.one-third,
	.two-third,
	.three-fourth,
	.one-fourth,
	.one-fifth,
	.two-fifth,
	.three-fifth,
	.four-fifth,
	.one-sixth,
	.five-sixth {
		&.last {
			margin-right: 0 !important;
		}
	}
}

div[class^="column-group-"] {
	.column {
		margin-bottom: 30px;
	}
}

@include mbp(480) {
	div[class^="column-group-"] {
		@include clearfix;
		&.align-center {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		&.align-bottom {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
		}
	}
	.column-group-2,
	.column-group-3,
	.column-group-4 {
		.column {
			@include column(1/2, $cycle: 2, $gutter: 5);
		}
	}
}

@include mbp(768) {
	.column-group-3,
	.column-group-4 {
		.column {
			@include column(1/3, $cycle: 3, $gutter: 5);
		}
	}
	.row {
		.one-half,
		.one-third,
		.two-third,
		.three-fourth,
		.one-fourth,
		.one-fifth,
		.two-fifth,
		.three-fifth,
		.four-fifth,
		.one-sixth,
		.five-sixth {
			margin-right: 5%;
			float: left;
		}
		.one-half { width: column-width(1/2, $gutter: 5); }
		.one-third { width: column-width(1/3, $gutter: 5); }
		.two-third { width: column-width(2/3, $gutter: 5); }
		.three-fourth { width: column-width(3/4, $gutter: 5); }
		.one-fourth { width: column-width(1/4, $gutter: 5); }
		.one-fifth { width: column-width(1/5, $gutter: 5); }
		.two-fifth { width: column-width(2/5, $gutter: 5); }
		.three-fifth { width: column-width(3/5, $gutter: 5); }
		.four-fifth { width: column-width(4/5, $gutter: 5); }
		.one-sixth { width: column-width(1/6, $gutter: 5); }
		.five-sixth { width: column-width(5/6, $gutter: 5); }
		&.align-center {
			display: flex;
			align-items: center;
		}
		&.align-bottom {
			display: flex;
			align-items: flex-end;
		}
	}
}

@include mbp(1000) {
	.column-group-4 {
		.column {
			@include column(1/4, $cycle: 4, $gutter: 5);
		}
	}
}

// WIDGETS
// ---------------

.searchform {
	form {
		padding-right: 50px;
		background: #EEE;
		border-radius: 40px;
		position: relative;
	}
	input {
		height: 40px;
		padding: 0 20px;
		background: none;
		border: none;
		font-size: 16px;
	}
	button {
		width: 40px;
		height: 40px;
		display: block;
		line-height: 40px;
		padding: 0;
		background: none;
		border: none;
		color: $textColor;
		font-size: 16px;
		position: absolute;
		text-align: center;
		right: 10px;
		top: 0;
	}
}

// COMMENTS
// ---------------

// #comments {
// 	margin-top: 60px;
// }

// .commentlist {
// 	list-style: none;
// 	margin: 0 0 $baseTypeSpacing;
// 	li.comment {
// 		margin: 0 0 20px;
// 		list-style: none;
// 		background: $color4;
// 		border-left: 3px solid #DDD;
// 		.comment-body {
// 			padding: 30px 20px 30px 70px;
// 			position: relative;
// 		}
// 		.comment-author {
// 			line-height: 1.2;
// 			img {
// 				position: absolute;
// 				top: 34px;
// 				left: 20px;
// 			}
// 			cite.fn {
// 				font-weight: 700;
// 				font-size: 16px;
// 				color: $textColor;
// 				a {
// 					color: $textColor;
// 					text-decoration: none;
// 					&:hover {
// 						color: $color1;
// 					}
// 				}
// 			}
// 			.says {
// 				font-style: italic;
// 				font-size: 13px;
// 			}
// 		}
// 		.comment-meta {
// 			margin: 0 0 20px;
// 			a {
// 				color: $textColor;
// 				font-size: 13px;
// 				text-decoration: none;
// 			}
// 		}
// 		.reply {
// 			a {
// 				@extend .btn;
// 				padding: 5px 10px;
// 				font-size: 11px;
// 			}
// 		}
// 		ul.children {
// 			margin: 20px 0 0 20px;
// 			padding: 0 0 1px;
// 			li.comment {
// 				background: rgba(#000, 0.05);
// 				border-left: 3px solid rgba(#000, 0.05);
// 			}
// 		}
// 	}
// 	#respond {
// 		padding: 30px 20px 20px;
// 		#{$all-text-inputs},
// 		select[multiple=multiple],
// 		select {
// 			background-color: #FFF;
// 		}
// 	}
// }

// #respond {
// 	.one-half {
// 		margin-bottom: 0;
// 	}
// 	.cancel-comment-reply {
// 		a {
// 			color: $textColor;
// 		}
// 	}
// }

// div.navigation {
// 	@include clearfix;
// 	.alignleft {
// 		margin: 0;
// 	}
// 	.alignright {
// 		margin: 0;
// 		text-align: right;
// 	}
// }

// .comment-nav {
// 	margin: 0 0 $baseTypeSpacing;
// 	@include clearfix;
// 	.next {
// 		width: 50%;
// 		float: right;
// 		text-align: right;
// 	}
// 	.prev {
// 		width: 50%;
// 		float: left;
// 	}
// }
