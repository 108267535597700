/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.process-a {
	width: 840px;
	max-width: 100%;
	margin: 60px auto;
	.step {
		padding: 50px 0;
		border-bottom: 1px solid $hrColor;
		.image {
			margin: 0 0 30px;
		}
		.num {
			margin: 0 0 20px;
			font-size: 13px;
			font-style: italic;
			color: #999;
		}
		h3 {
			margin: 0 0 20px;
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:first-child {
			border-top: 1px solid $hrColor;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	@include mbp(480) {
		.step {
			@include clearfix;
			.image {
				margin-bottom: 0;
				@include column(1/2, $cycle: 2, $gutter: 5);
			}
			.text {
				@include column(1/2, $cycle: 2, $gutter: 5);
			}
		}
	}
	@include mbp(768) {
		.step {
			padding: 60px 0;
		}
	}
}
