/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.section-logo-scroller {
	padding: 60px 20px;
	background: $color4;
	h2 {
		margin: 0 0 50px;
		text-align: center;
	}
	.logo-scroller {
		margin: 0 -20px;
		.slider {
			.slide {
				height: 100px;
				line-height: 100px;
				padding: 0 15px;
				text-align: center;
				img {
					width: auto;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}
	}
	@include mbp(768) {
		padding: 60px 5%;
	}
	@include mbp(960) {
		padding: 100px 5%;
	}
}
