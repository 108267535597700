/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.links {
	border-top: 1px solid $hrColor;
	.link {
		border-bottom: 1px solid $hrColor;
		a {
			display: block;
			padding: 30px 20px;
			text-decoration: none !important;
			transition: 0.5s ease;
			&:hover {
				background: $color4;
				h3 {
					color: $color2;
				}
			}
		}
		h3 {
			margin: 0 0 5px;
			font-size: 24px;
			transition: 0.5s ease;
		}
		.url {
			color: #999;
			font-size: 13px;
			font-weight: 400;
		}
		p {
			margin: 10px 0 0;
			color: $textColor;
		}
	}
}
