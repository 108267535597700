/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.related-posts-a {
	margin-top: 60px;
	h2 {
		font-size: 30px;
	}
	.archive-post {
		&.post-style-a {
			margin-bottom: 40px;
		}
	}
}
