/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.files {
	margin: 60px 0;
	clear: both;
	ul {
		margin: 0;
		li {
			display: block;
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			border-bottom: 1px solid #DDD;
			a {
				display: block;
				padding: 20px 20px 20px 50px;
				position: relative;
				color: $textColor;
				&:before {
					position: absolute;
					left: 20px;
					top: 17px;
					font-family: FontAwesome;
					font-size: 20px;
					font-weight: normal;
					color: $color1;
					content: '\f0a9';
				}
				&[href$=".pdf"] {
					&:before {
						content: '\f1c1';
					}
				}
				&[href$=".doc"],
				&[href$=".docx"] {
					&:before {
						content: '\f1c2';
					}
				}
				&[href$=".xls"],
				&[href$=".csv"] {
					&:before {
						content: '\f1c3';
					}
				}
				&[href$=".ppt"] {
					&:before {
						content: '\f1c4';
					}
				}
				&[href$=".jpg"],
				&[href$=".jpeg"],
				&[href$=".png"] {
					&:before {
						content: '\f1c5';
					}
				}
				&[href$=".mp3"],
				&[href$=".m4a"],
				&[href$=".wav"],
				&[href$=".wma"],
				&[href$=".flac"] {
					&:before {
						content: '\f1c7';
					}
				}
				&[href$=".zip"],
				&[href$=".gzip"] {
					&:before {
						content: '\f1c6';
					}
				}
				&[href$=".avi"],
				&[href$=".flv"],
				&[href$=".mov"],
				&[href$=".wmv"],
				&[href$=".mp4"] {
					&:before {
						content: '\f1c8';
					}
				}
				&:hover {
					text-decoration: none;
					background: $color4;
				}
			}
			&:first-child {
				border-top: 1px solid #DDD;
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include mbp(768) {
		ul {
			li {
				a {
					padding: 30px 20px 30px 85px;
					&:before {
						left: 30px;
						top: 20px;
						font-size: 30px;
					}
				}
			}
		}
	}
}
