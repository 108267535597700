/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.tabs-b {
	margin: 60px 0;
	clear: both;
	.tab-nav {
		margin: 0;
		font-weight: 700;
		font-size: 16px;
		line-height: 1.2;
		li {
			display: block;
			margin: 0;
			a {
				display: block;
				padding: 20px;
				color: $textColor;
				background: #FFF;
				border-width: 1px 1px 0;
				border-style: solid;
				border-color: $hrColor;
				br {
					display: none;
				}
				&:hover {
					text-decoration: none;
					color: $color2;
				}
			}
			&.active {
				a {
					background: $color1;
					color: #FFF;
					border-color: $color1;
				}
			}
		}
	}
	.tab-window {
		.tab-panel {
			padding: 30px 20px;
			background: $color4;
			border: 1px solid $hrColor;
			font-size: 16px;
			@include clearfix;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	@include mbp(768) {
		.tab-nav {
			width: 100%;
			@include clearfix;
			li {
				float: left;
				text-align: center;
				a {
					padding: 20px 30px;
					border-width: 1px 1px 0 0;
				}
				&:first-child {
					a {
						border-left-width: 1px;
					}
				}
			}
		}
		.tab-window {
			.tab-panel {
				padding: 45px 50px 30px;
			}
		}
	}
}
