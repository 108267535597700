/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.section-featured-content {
	.featured-content {
		padding: 100px 20px;
		color: #FFF;
		background: no-repeat center center / cover;
		.container {
			width: 100%;
		}
		.text {
			max-width: 550px;
			float: right;
		}
		h3 {
			margin: 0 0 15px;
			color: #FFF;
			font-size: 30px;
		}
		.buttons {
			@include clearfix;
			.btn {
				display: block;
				margin: 0 10px 10px 0;
				float: left;
			}
		}
		&:nth-child(even) {
			.container {
				max-width: 1500px;
			}
			.text {
				float: left;
			}
		}
		@include mbp(768) {
			display: flex;
			align-items: center;
			min-height: 600px;
			padding: 100px 5%;
			.text {
				width: percentage(550/1200);
			}
			&:nth-child(even) {
				.text {
					width: percentage(550/1500);
				}
			}
		}
		@include mbp(960) {
			h3 {
				font-size: 36px;
			}
		}
	}
}
