/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.slider {
	position: relative;
	@include clearfix;
	.slide {
		outline: none;
		float: left;
		position: relative;
		img {
			width: 100%;
		}
	}
}

.slider-arrow {
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	position: absolute;
	top: 50%;
	z-index: 100;
	margin: -25px 0 0;
	color: #FFF;
	font-size: 50px;
	cursor: pointer;
	transition: color 0.3s ease;
	&:before {
		line-height: 50px;
	}
	&.prev {
		left: 0;
	}
	&.next {
		right: 0;
	}
	&:hover {
		color: $color1;
	}
}

.slick-dots {
	width: 100%;
	margin: 0;
	padding: 0;
	text-align: center;
	position: absolute;
	bottom: 10%;
	left: 0;
	z-index: 2;
	li {
		display: inline-block;
		margin: 0 5px;
		button {
			width: 10px;
			height: 10px;
			display: block;
			padding: 0;
			border: 1px solid #FFF;
			border-radius: 100%;
			background: none;
			cursor: pointer;
			text-indent: 100%;
			overflow: hidden;
		}
		&.slick-active {
			button {
				background: #FFF;
			}
		}
	}
}
