/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.process-b {
	width: 840px;
	max-width: 100%;
	margin: 60px auto;
	.step {
		padding: 50px 0;
		border-bottom: 1px solid $hrColor;
		.num {
			width: 150px;
			height: 150px;
			margin: 0 0 30px;
			padding-top: 45px;
			background: $color1;
			border-radius: 100%;
			color: #FFF;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 2px;
			line-height: 1;
			span {
				display: block;
				padding-top: 5px;
				font-size: 48px;
				letter-spacing: 0;
			}
		}
		.text {
			.thumb {
				margin: 0 0 40px;
			}
			h3 {
				margin: 0 0 20px;
			}
		}
		&:first-child {
			border-top: 1px solid $hrColor;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	@include mbp(480) {
		.step {
			min-height: 150px;
			@include clearfix;
			.num {
				margin-bottom: 0;
				float: left;
			}
			.text {
				margin-left: 180px;
			}
		}
	}
	@include mbp(768) {
		.step {
			.text {
				margin-left: 210px;
			}
		}
	}
}
