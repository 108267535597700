/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.latest-posts {
	padding: 60px 20px 0;
	background: $color4;
	.title {
		margin: 0 0 50px;
		h2 {
			margin: 0;
		}
		a {
			display: none;
		}
	}
	.more {
		margin: 0 0 60px;
		text-align: center;
	}
	@include mbp(768) {
		padding: 100px 5% 40px;
		.title {
			position: relative;
			h2 {
				margin-right: 100px;
			}
			a {
				display: block;
				position: absolute;
				top: 6px;
				right: 0;
				padding: 10px 20px;
				font-size: 11px;
			}
		}
		.more {
			display: none;
		}
	}
}
