/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@viewport { width : device-width; }

*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	font-size: $baseFontSize;
}

body {
	background: #FFF;
	color: $textColor;
	line-height: $baseLineHeight;
	font-family: $baseFontFamily;
	font-weight: 300;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	@include clearfix;
	&.wide {
		max-width: 1500px;
	}
}
.row {
	width: 100%;
	clear: both;
	@include clearfix;
}

// HEADER
// ---------------

@import "headers/header-a";
// @import "headers/header-b";
// @import "headers/header-c";
// @import "headers/header-d";

// HERO
// ---------------

@import "heros/hero-a";
// @import "heros/hero-b";

// MAIN / CONTENT
// ---------------

.main {
	padding: 60px 20px;
	background: #FFF;
	position: relative;
	.main-title {
		margin: 0 0 60px;
		h1 {
			margin: 0;
		}
	}
	.content {
		margin-bottom: 70px;
		@include clearfix;
	}
	.content-narrow {
		max-width: 840px;
		margin: 0 auto;
		@include clearfix;
	}
	.content-wide {
		width: 100%;
		@include clearfix;
	}
	@include mbp(768) {
		padding: 60px 5%;
	}
	@include mbp(960) {
		padding: 100px 5% 80px;
		.content {
			width: percentage(840/1200);
			margin-bottom: 0;
			float: left;
		}
		.sidebar {
			width: percentage(300/1200);
			float: right;
		}
	}
}

// SUBNAV
// ---------------

// @import "subnav";

// SIDEBARS
// ---------------

@import "sidebars/sidebar-a";
// @import "sidebars/sidebar-b";

// QUICK LINKS
// ---------------

@import "quicklinks/quicklinks-a";
// @import "quicklinks/quicklinks-b";
// @import "quicklinks/quicklinks-c";

// CTA
// ---------------

@import "cta/cta-a";

// FOOTERS
// ---------------

@import "footers/footer-a";
// @import "footers/footer-b";
// @import "footers/footer-c";

// SUBSCRIBE
// ---------------

@import "subscribe/subscribe-a";

// BLOG
// ---------------

@import "blog/post-a";
@import "blog/post-b";
// @import "blog/post-c";
@import "blog/postnav";

@import "blog/post-detail-a";
// @import "blog/post-detail-c";
@import "blog/share";
@import "blog/related-posts-a";
// @import "blog/related-posts-b";
// @import "blog/related-posts-c";

// HOME
// ---------------

.page-template-template-home {
	@include mbp(1290) {
		#main {
			background: url(assets/img/main_bg_home.png) no-repeat center 670px;
		}
	}
}

#column-rows-intro {
	img {
		&:last-child {
			margin-bottom: 0;
		}
	}
	@include mbp(960) {
		font-size: 20px;
		line-height: 1.8;
	}
}

.breaking-news {
	h2 {
		margin: 0 0 50px;
	}
}

.become-member {
	margin: 60px 0;
	text-align: center;
	.btn {
		paddinG: 20px 30px;
		font-size: 24px;
	}
	@include mbp(768) {
		margin: 150px 0 100px;
		.btn {
			padding: 32px 280px 32px 60px;
			font-size: 30px;
			position: relative;
			&:after {
				content: '';
				width: 237px;
				height: 157px;
				display: block;
				background: url(assets/img/become_member.png) no-repeat;
				position: absolute;
				bottom: 0;
				right: 30px;
			}
		}
	}
}

.upcoming-event {
	width: 100%;
	clear: both;
	margin: 60px 0;
	h2 {
		margin: 0 0 50px;
	}
	figure {
		margin: 0;
		background: no-repeat center center / cover;
		img {
			width: 100%;
		}
	}
	.inner {
		padding: 40px 20px;
		background: $color4;
		font-size: 16px;
	}
	time {
		display: block;
		margin: 0 0 20px;
		line-height: 0.8;
		font-size: 30px;
		font-weight: 700;
		color: $color1;
		@include clearfix;
		.day {
			margin-right: 10px;
			float: left;
			font-size: 72px;
		}
		.month {
			padding-top: 8px;
		}
	}
	h3 {
		margin: 0 0 10px;
		font-size: 30px;
		a {
			color: $textColor;
			text-decoration: none;
			&:hover {
				color: $color2;
			}
		}
	}
	p {
		margin: 0 0 15px;
	}
	.read-more {
		font-size: 13px;
	}
	.category {
		font-weight: 700;
		font-size: 13px;
		a {
			text-decoration: none;
		}
	}
	@include mbp(480) {
		.inner {
			padding: 50px 30px;
		}
	}
	@include mbp(768) {
		article {
			position: relative;
		}
		figure {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			img {
				display: none;
			}
		}
		.inner {
			width: 400px;
			min-height: 500px;
			display: flex;
			flex-direction: column;
			padding: 60px 5%;
			background: rgba(#FFF, 0.9);
			position: relative;
			z-index: 2;
		}
		header {
			margin-top: auto;
		}
		.category {
			margin-bottom: auto;
		}
	}
	@include mbp(960) {
		margin: 100px 0;
	}
	@include mbp(1200) {
		.inner {
			min-height: 655px;
			padding: 60px 50px;
		}
	}
}

.main.events-text {
	background: $color4;
}

.main.upcoming-events,
.main.past-events {
	width: 100%;
	padding: 60px 20px 0;
	.title {
		margin: 0 0 50px;
		h2 {
			margin: 0;
		}
		div {
			display: none;
		}
	}
	.view-all {
		margin-bottom: 60px;
	}
	.content {
		margin-bottom: 0;
	}
	@include mbp(768) {
		padding: 60px 5% 0;
		.title {
			display: flex;
			align-items: center;
			h2 {
				margin: 0 5% 0 0;
				flex: 1;
			}
			div {
				display: block;
				.btn {
					font-size: 13px;
				}
			}
		}
		.view-all {
			display: none;
		}
	}
	@include mbp(960) {
		padding: 100px 5% 40px;
	}
}

.aspa-twitter-feed {
	width: 100%;
	margin: 60px 0 0;
	clear: both;
	.follow {
		margin: 0 0 30px;
		h2 {
			margin: 0 0 20px;
		}
		.social-icons {
			font-size: 30px;
			line-height: 1;
			a {
				display: inline-block;
				margin: 0 20px 0 0;
			}
		}
	}
	.bubble {
		padding: 40px;
		background: $color4;
		border-radius: 50px;
	}
	@include mbp(768) {
		@include clearfix;
		.follow {
			width: column-width(1/3, 5);
			margin-right: 5%;
			margin-bottom: 0;
			float: left;
		}
		.bubble {
			width: column-width(2/3, 5);
			float: right;
		}
	}
	@include mbp(960) {
		margin: 100px 0 0;
	}
	@include mbp(1050) {
		.follow {
			position: relative;
			z-index: 2;
			margin-bottom: -60px;
		}
		.bubble {
			width: 910px;
			height: 473px;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: url(assets/img/twitter_bubble.png) no-repeat center center;
			.inner {
				max-width: 560px;
				margin: auto;
			}
		}
	}
	@include mbp(1290) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.follow {
			width: 100%;
			margin-bottom: 0;
		}
		.bubble {
			flex-shrink: 0;
		}
	}
}

// CONTACT
// ---------------

@import "contact/contact-a";
// @import "contact/contact-c";

// WOOCOMMERCE
// ---------------

@import "woocommerce";

// ACCORDIONS
// ---------------

@import "accordions/accordion-a";
// @import "accordions/accordion-b";
// @import "accordions/accordion-c";
// @import "accordions/accordion-d";

// TABBED CONTENT
// ---------------

// @import "tabs/tabs-a";
@import "tabs/tabs-b";
// @import "tabs/tabs-c";
// @import "tabs/tabs-d";

// SOCIAL FEEDS
// ---------------

// @import "feeds/facebook-a";
// @import "feeds/facebook-b";
// @import "feeds/facebook-c";

// GALLERIES
// ---------------

@import "galleries/gallery-a";

// SLIDER GALLERIES
// ---------------

@import "slider-galleries/gallery-a";

// TEAM
// ---------------

@import "team/team-a";
@import "team/team-detail-a";

@import "team/team-b";

// TESTIMONIALS
// ---------------

@import "testimonials/testimonial-a";

// PROCESS
// ---------------

@import "process/process-a";
@import "process/process-b";

// SECTIONS
// ---------------

@import "sections/latest-posts";
@import "sections/testimonials";
@import "sections/logo-scroller";
@import "sections/featured-content";
@import "sections/image";
@import "sections/image-content-split";
@import "sections/slider";

// OTHER
// ---------------

@import "landing";
@import "popup-magnific";
@import "search";
@import "files";
@import "blocks";
@import "links";
@import "sliders";

.scrollable {
	overflow-x: auto;
	&.has-scroll {
		position: relative;
		overflow: hidden;
		.scrollable-inner {
			overflow-x: auto;
			&::-webkit-scrollbar {
				height: 12px;
			}
			&::-webkit-scrollbar-track {
				box-shadow: 0 0 2px rgba(0,0,0,0.15) inset;
				background: #f0f0f0;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 6px;
				background: #CCC;
			}
		}
		&:after {
			content: '';
			width: 50px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 100%;
			border-radius: 10px 0 0 10px / 50% 0 0 50%;
			box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
		}
	}
}

.screen-reader {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

.pace {
	display: none;
	@include mbp(960) {
		pointer-events: none;
		user-select: none;
		display: block;
		.pace-progress {
			background: $color1;
			position: fixed;
			z-index: 2000;
			top: 0;
			right: 100%;
			width: 100%;
			height: 2px;
		}
		.pace-progress-inner {
			display: block;
			position: absolute;
			right: 0px;
			width: 100px;
			height: 100%;
			opacity: 1.0;
			transform: rotate(3deg) translate(0px, -4px);
		}
		.pace-activity {
			display: block;
			position: fixed;
			z-index: 2000;
			top: 15px;
			right: 15px;
			width: 14px;
			height: 14px;
			border: solid 2px transparent;
			border-top-color: $color1;
			border-left-color: $color1;
			border-radius: 10px;
			animation: pace-spinner 400ms linear infinite;
		}
		&.pace-inactive {
			display: none;
		}
	}
}

.main-membership {
	background: url(assets/img/main_bg_home.png) no-repeat top left;
}

.membership-list {
	max-width: 500px;
	margin: 0 auto;
	.membership {
		border: 2px solid $color1;
		border-radius: 20px;
		padding: 40px 20px;
		margin: 20px 0;
		text-align: center;
		background: #fff;
	}
	h3{
		font-size: 24px;
		margin-bottom: 10px;
	}
	.type {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 10px;
	}
	.price {
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 10px;
	}
	@include mbp(768) {
		max-width: 100%;
		margin: 0;
		display:flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.membership {
			flex: 0 49%;
			margin: 10px 0;
		}
	}
	@include mbp(1200) {
		.membership {
			flex: none;
			width: 24%;
			padding: 60px 30px;
		}
	}
	@include mbp(1600) {
		.membership {
			width: 23%;
			padding: 60px 40px;
		}
	}
}

.main-no-hero {
	border-top: 1px solid #eee;
	.page-title {
		text-align: center;
	}
	@include mbp(1200) {
		.page-title {
			margin-bottom: 50px;
		}
	}
}

@import "membership";

.posts-container {
	@include clearfix;
}

.archive-event {
	margin-bottom: 60px;
	font-size: 16px;
	position: relative;
	a {
		transition: 0.4s ease;
	}
	figure {
		margin: 0 0 25px;
		a {
			display: block;
			overflow: hidden;
			&:hover {
				img {
					transform: scale(1.08);
				}
			}
		}
		img {
			width: 100%;
			transition: 0.4s ease;
		}
	}
	header {
		h3 {
			margin: 0 0 20px;
			font-size: 24px;
			a {
				color: $textColor;
				text-decoration: none;
				&:hover {
					color: $color2;
				}
			}
		}
	}
	.event-details {
		margin: 0;
	}
	@include mbp(650) {
		@include column(1/2, $cycle: 2, $gutter: 5);
	}
	@include mbp(960) {
		@include column(1/3, $cycle: 3, $gutter: 3.5);
	}
}

.event-details {
	margin: 0 0 50px;
	padding: 5%;
	background: $color4;
	border-radius: 5px;
	table {
		width: 100%;
		font-size: 13px;
		th {
			width: 30px;
			padding: 0 10px 0 0;
			font-weight: 700;
			text-align: left;
			vertical-align: top;
		}
		td {
			padding: 0;
			text-align: left;
			vertical-align: top;
		}
	}
}

.event-cta {
	width: 100%;
	clear: both;
	margin: 50px 0;
	text-align: center;
	h4 {
		margin: 0 0 20px;
		font-size: 20px;
	}
	.buttons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		div {
			margin: 0 5px 10px;
		}
	}
}

.renew-membership-form {
	.block {
		margin-bottom: 20px;
	}
	p {
		font-weight: 700;
		margin: 0 0 5px;
	}
	.radios {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		background: $color4;
		border: 1px solid #ddd;
		border-left: 0;
		font-size: 13px;
	}
	.radio {
		border-left: 1px solid #ddd;
		padding: 10px 5px 5px;
	}
	span {
		margin-left: 10px;
		line-height: 1;
	}
	label {
		margin: 0;
	}
	em {
		font-style: normal;
		color: $color1;
		font-weight: 700;
	}
	.submit-wrapper {
		text-align: right;
	}
	@include mbp(768) {
		.block {
			display: flex;
			justify-content: space-between;
		}
		p {
			width: 40%;
		}
		.radios {
			width: 55%;
			font-size: 14px;
		}
		em {
			padding-left: 25px;
		}
	}
	@include mbp(1200) {
		.radio {
			padding: 15px 15px 10px;
		}
		.submit-wrapper {
			margin-top: 30px;
		}
	}
}
