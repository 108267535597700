/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.post-detail-a {
	.post-top {
		min-height: 100px;
		margin: 0 0 50px;
		position: relative;
		time {
			display: block;
			width: 100px;
			height: 100px;
			padding-top: 20px;
			border-radius: 100%;
			background: $color1;
			color: #FFF;
			text-align: center;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			top: 0;
			left: 0;
			.day {
				width: 100%;
				margin: 0 0 5px;
				font-size: 40px;
				line-height: 0.8;
			}
			.month-year {
				width: 100%;
				font-size: 13px;
			}
		}
		.post-figure {
			margin: 0;
		}
	}
	article.has-post-thumbnail {
		.post-top {
			time {
				top: -10px;
				left: -10px;
			}
		}
	}
	.post-figure {
		margin: 0 0 50px;
		img {
			margin: 0 auto;
		}
	}
	.post-header {
		margin: 0 0 40px;
		.category {
			margin: 0 0 10px;
			font-weight: 700;
			font-size: 13px;
			a {
				color: $color1;
				text-decoration: none;
				&:hover {
					color: $color2;
				}
			}
		}
		h1 {
			margin: 0;
			font-size: 36px;
		}
	}
	.post-tags {
		margin: 60px 0 0;
		padding: 30px;
		background: $color4;
		p {
			margin: 0;
		}
		a {
			color: $textColor;
			&:hover {
				text-decoration: none;
				color: $color1;
			}
		}
	}
	.post-share {
		margin: 0;
		padding: 30px 0 20px;
		border-bottom: 1px solid $hrColor;
	}
	.backtoblog {
		margin-top: 20px;
		font-size: 13px;
		a {
			text-decoration: none;
		}
		i {
			margin-right: 3px;
		}
	}
	@include mbp(768) {
		.post-tags {
			padding: 40px 50px;
		}
	}
	@include mbp(1200) {
		.post-header {
			.meta {
				.category {
					a {
						padding: 10px 30px;
					}
				}
			}
		}
	}
}
