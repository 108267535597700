/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

ul.team {
	&.team-a {
		margin: 40px 0 0;
		li {
			display: block;
			margin-bottom: 60px;
		}
		a {
			display: block;
			text-decoration: none;
			color: $textColor;
			&:hover {
				h3 {
					color: $color1;
				}
			}
		}
		.photo {
			margin: 0 0 20px;
			img {
				width: 100%;
			}
		}
		h3 {
			margin: 0 0 10px;
			font-size: 24px;
			font-weight: 400;
			color: $textColor;
			transition: 0.3s ease;
		}
		p {
			margin: 0;
			font-size: 16px;
			color: #999;
		}
		&:first-child {
			margin-top: 0;
		}
		@include mbp(480) {
			&.cols-2,
			&.cols-3,
			&.cols-4 {
				@include clearfix;
				li {
					@include column(1/2, $cycle: 2, $gutter: 5);
				}
			}
		}
		@include mbp(768) {
			&.cols-3,
			&.cols-4 {
				li {
					@include column(1/3, $cycle: 3, $gutter: 5);
				}
			}
		}
		@include mbp(960) {
			&.cols-4 {
				li {
					@include column(1/4, $cycle: 4, $gutter: 5);
				}
			}
		}
	}
}
