/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@import "contact";

.contact-map-a {
	width: 100%;
	min-height: 300px;
	margin: 0 0 60px;
	padding: 0 0 41.67%;
	position: relative;
	background: #EEE;
	iframe {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}
