.cssanimations {
	.section-slider .text,
	.section-slider .scroll-down {
		opacity: 0;
	}
	.pace-done {
		.section-slider .text,
		.section-slider .scroll-down {
			animation: fadeInUp 0.5s 0.2s forwards;
		}
	}
}

.section-slider {
	height: 300px;
	width: 100%;
	background: #000;
	position: relative;
	.section-slides {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		.slick-list,
		.slick-track {
			width: 100%;
			height: 100%;
			.slide {
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center bottom;
				background-size: cover;
				position: relative;
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					content: '';
					background-image: linear-gradient(to bottom, transparent 40%, rgba(0,0,0,0.3) 90%);
				}
			}
		}
		.slick-slide > div {
			width: 100%;
			height: 100%;
		}
	}
	.text {
		height: 100%;
		padding: 20px;
		display: flex;
	  justify-content: center;
	  flex-direction: column;
		position: relative;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #FFF;
	}
	p {
		color: #fff;
	}
	.scroll-down {
		display: none;
	}
	.desktop {
		display: none;
	}
	.container {
		max-width: 1500px;
		height: 100%;
	}
	@include mbp(768) {
		height: 400px;
		.text {
			padding: 0 5%;
			@include clearfix;
			.text-left {
				justify-content: flex-start;
				width: 50%;
			}
		}
		.text-left {
			.block {
				justify-content: flex-start;
				width: 50%;
			}
		}
		.text-bottom {
			justify-content: flex-end;
			padding-bottom: 30px;
			.block {
				width: 50%;
			}
		}
		.text-right {
			flex-direction: unset;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			.block {
				width: 50%;
			}
		}
	}
	@include mbp(960) {
		.desktop {
			display: block;
		}
	}
	@include mbp(1200) {
		height: 600px;
		&.full-screen {
			height: 1080px;
			height: 100vh;
		}
		.scroll-down {
			display: block;
			width: 60px;
			height: 60px;
			position: absolute;
			bottom: 100px;
			left: 50%;
			z-index: 3;
			color: #DDD;
			text-align: center;
			font-size: 20px;
			margin-left: -30px;
			&:hover {
				text-decoration: none;
				color: $color1;
			}
			img {
				animation: bounce 2s ease infinite;
				line-height: 60px;
				display: block;
			}
			i {
				font-size: 60px;
				animation: bounce 2s ease infinite;
				line-height: 60px;
				display: block;
			}
		}
		.text-bottom {
			padding-bottom: 80px;
		}
	}
	@include mbp(1500) {
		height: 1080px;
	}
	@include mbp(1600) {
		.text {
			padding-left: 0;
			padding-right: 0;
		}
	}
}