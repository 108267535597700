/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.posts-container {
	@include clearfix;
}

.archive-post {
	&.post-style-a {
		margin-bottom: 60px;
		font-size: 16px;
		position: relative;
		a {
			transition: 0.4s ease;
		}
		figure {
			margin: 0 0 25px;
			a {
				display: block;
				overflow: hidden;
				&:hover {
					img {
						transform: scale(1.08);
					}
				}
			}
			img {
				width: 100%;
				transition: 0.4s ease;
			}
		}
		time {
			display: block;
			width: 80px;
			height: 80px;
			padding-top: 15px;
			border-radius: 100%;
			background: $color1;
			color: #FFF;
			text-align: center;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			top: -15px;
			left: -15px;
			.day {
				width: 100%;
				margin: 0 0 5px;
				font-size: 30px;
				line-height: 0.8;
			}
			.month-year {
				width: 100%;
				font-size: 13px;
			}
		}
		header {
			h3 {
				margin: 0 0 10px;
				font-size: 24px;
				a {
					color: $textColor;
					text-decoration: none;
					&:hover {
						color: $color2;
					}
				}
			}
		}
		.category {
			font-weight: 700;
			font-size: 13px;
			a {
				color: $color1;
				text-decoration: none;
				&:hover {
					color: $color2;
				}
			}
		}
		@include mbp(650) {
			@include column(1/2, $cycle: 2, $gutter: 5);
		}
		@include mbp(960) {
			@include column(1/3, $cycle: 3, $gutter: 3.5);
		}
	}
}
