/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

 .testimonials {
	margin: 60px 0;
	&.testimonials-a {
		border-top: 1px solid $hrColor;
		.testimonial {
			min-height: 200px;
			padding: 50px 0;
			border-bottom: 1px solid $hrColor;
			.photo {
				margin: 0 0 30px;
				img {
					border-radius: 500px;
				}
				.fa {
					font-size: 100px;
					color: #DDD;
					line-height: 1;
				}
			}
			.text {
				font-style: italic;
				font-size: 20px;
			}
			.name {
				font-size: 13px;
				font-weight: 700;
				font-style: normal;
				text-transform: uppercase;
				span {
					font-weight: 300;
				}
			}
		}
		@include mbp(600) {
			.testimonial {
				position: relative;
				@include clearfix;
				.photo {
					margin-bottom: 0;
					@include column(1/3, $cycle: 2, $gutter: 5);
					text-align: center;
					img {
						margin: 0 auto;
					}
				}
				.text {
					@include column(2/3, $cycle: 2, $gutter: 5);
				}
			}
		}
		@include mbp(768) {
			.testimonial {
				.photo {
					@include column(1/4, $cycle: 2, $gutter: 5);
					text-align: center;
				}
				.text {
					@include column(3/4, $cycle: 2, $gutter: 5);
				}
			}
		}
	}
	&:first-child {
		margin-top: 0;
	}
}
