/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.blocks {
	margin-top: 60px;
	.block {
		margin-bottom: 60px;
		a {
			display: block;
			text-decoration: none !important;
			transition: 0.5s ease;
			&:hover {
				img {
					transform: scale(1.08);
				}
				h3 {
					color: $color2;
				}
			}
		}
		.thumb {
			margin: 0 0 30px;
			overflow: hidden;
		}
		img {
			width: 100%;
			transition: 0.4s ease;
		}
		h3 {
			margin: 0;
			font-size: 24px;
			color: $color1;
			transition: 0.5s ease;
		}
		p {
			margin: 20px 0 0;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	@include mbp(480) {
		&.cols-2,
		&.cols-3,
		&.cols-4 {
			@include clearfix;
			.block {
				@include column(1/2, $cycle: 2, $gutter: 5);
			}
		}
	}
	@include mbp(768) {
		&.cols-3,
		&.cols-4 {
			.block {
				@include column(1/3, $cycle: 3, $gutter: 5);
			}
		}
	}
	@include mbp(960) {
		&.cols-4 {
			.block {
				@include column(1/4, $cycle: 4, $gutter: 5);
			}
		}
	}
}
