/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.subscribe-a {
	padding: 40px 20px;
	background: $color2;
	color: #FFF;
	.text {
		margin: 0 auto 30px;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		line-height: 1.2;
	}
	.form {
		max-width: 760px;
		margin: 0 auto;
		.gform_confirmation_message {
			color: $textColor;
		}
		div.gform_wrapper {
			margin: 0;
			.validation_error {
				display: none;
			}
			div.gform_body {
				width: 100%;
				ul.gform_fields {
					li.gfield {
						margin: 0 0 15px;
						padding: 0;
						.ginput_container {
							margin-top: 0;
						}
						input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
						textarea {
							height: 40px;
							padding: 0;
							background: none;
							border: none;
							border-radius: 0;
							border-bottom: 2px solid #FFF;
							color: #FFF;
							@include placeholder {
								color: #FFF;
							}
						}
						&.gfield_error {
							border: none;
							background: none;
							.validation_message {
								color: #FFF;
								font-weight: 300;
							}
						}
					}
				}
			}
			div.gform_footer {
				margin: 0;
				input[type="submit"].gform_button {
					width: 100%;
					height: 40px;
					display: block;
					padding: 0;
					font-size: 13px;
					border-color: #FFF;
					color: #FFF;
					&:hover,
					&:active {
						background: #FFF;
						color: $textColor;
					}
				}
			}
		}
	}
	@include mbp(480) {
		.form {
			div.gform_wrapper {
				div.gform_body {
					ul.gform_fields {
						@include clearfix;
						li.gfield {
							@include column(1/2, $cycle: 2, $gutter: 3);
						}
					}
				}
			}
		}
	}
	@include mbp(600) {
		.form {
			div.gform_wrapper {
				@include clearfix;
				div.gform_body {
					@include column(0.8, $gutter: 1.2);
					float: left;
					ul.gform_fields {
						li.gfield {
							margin-bottom: 0;
							@include column(1/2, $cycle: 2, $gutter: 1.5);
						}
					}
				}
				div.gform_footer {
					@include column(0.2, $gutter: 1.2);
					clear: none;
				}
			}
		}
	}
	@include mbp(768) {
		padding: 40px 5%;
	}
	@include mbp(1240) {
		.inner {
			width: 100%;
			display: flex;
			align-items: center;
			@include clearfix;
		}
		.text {
			margin-bottom: 0;
			@include column(0.36, $cycle: 2, $gutter: 5);
			text-align: left;
		}
		.form {
			max-width: 100%;
			@include column(0.64, $cycle: 2, $gutter: 5);
		}
	}
}
