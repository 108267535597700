/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.team-detail {
	&.team-detail-a {
		max-width: 840px;
		margin: 0 auto;
		padding: 0 0 60px;
		.profile {
			margin: 0 0 50px;
		}
		.photo {
			margin-bottom: $baseTypeSpacing;
		}
		.text {
			h2 {
				margin: 0 0 10px;
				font-size: 24px;
			}
			p {
				margin: 0;
				font-size: 16px;
				color: #999;
			}
		}
		@include mbp(768) {
			.profile {
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include clearfix;
			}
			.photo {
				width: percentage(390/840);
				margin-right: percentage(60/840);
				margin-bottom: 0;
				float: left;
			}
			.text {
				width: percentage(390/840);
				padding-top: 60px;
				float: right;
			}
		}
	}
}
