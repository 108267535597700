/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.section-map {
	width: 100%;
	min-height: 300px;
	max-height: 600px;
	padding: 0 0 31.25%;
	position: relative;
	background: #EEE;
	iframe {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
}

ul.contacts {
	margin: 0 0 $baseTypeSpacing;
	li {
		display: block;
		padding-left: 30px;
		margin: 0 0 10px;
		position: relative;
		a {
			color: $textColor;
			&:hover {
				text-decoration: none;
				color: $color1;
			}
		}
		&:before {
			width: 25px;
			display: block;
			position: absolute;
			left: 0;
			font-family: FontAwesome;
			text-align: center;
		}
		&.address {
			&:before {
				content: '\f041';
			}
		}
		&.phone {
			&:before {
				content: '\f095';
			}
		}
		&.mobile {
			&:before {
				content: '\f10b';
			}
		}
		&.fax {
			&:before {
				content: '\f1ac';
			}
		}
		&.email {
			&:before {
				content: '\f003';
			}
		}
		&.facebook {
			&:before {
				content: '\f09a';
			}
		}
		&.twitter {
			&:before {
				content: '\f099';
			}
		}
		&.youtube {
			&:before {
				content: '\f16a';
			}
		}
		&.instagram {
			&:before {
				content: '\f16d';
			}
		}
	}
	@include mbp(1200) {
		font-size: 20px;
		li {
			margin: 0 0 25px;
			padding-left: 40px;
		}
	}
}

dl.contacts {
	margin: 0 0 $baseTypeSpacing;
	@include clearfix;
	dt,
	dd {
		margin-bottom: 20px;
	}
	dt {
		width: 70px;
		float: left;
		clear: left;
		font-weight: 700;
	}
	dd {
		margin-left: 80px;
	}
}

ul.hours {
	display: table;
	margin: 0 0 $baseTypeSpacing;
	line-height: 1.2;
	font-size: 13px;
	li {
		display: table-row;
		margin: 0;
		.day {
			display: table-cell;
			vertical-align: top;
			padding: 5px 20px 5px 0;
			font-weight: 400;
		}
		.time {
			display: table-cell;
			vertical-align: top;
			padding: 5px 0;
		}
	}
}