/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.gallery-a {
	margin: 60px 0;
	@include clearfix;
	.gallery-item {
		margin-bottom: 1%;
		@include column(1/2, $cycle: 2, $gutter: 1);
		a {
			display: block;
			text-decoration: none;
			transition: 0.3s ease;
			&:hover {
				.thumb {
					&:after {
						opacity: 1;
						transform: none;
					}
				}
				img {
					transform: scale(1.08);
					opacity: 0.7;
				}
			}
		}
		.thumb {
			overflow: hidden;
			position: relative;
			background: #000;
			&:after {
				content: '\f00e';
				width: 50px;
				height: 50px;
				line-height: 50px;
				text-align: center;
				font-family: FontAwesome;
				font-size: 30px;
				font-weight: normal;
				color: #FFF;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -25px 0 0 -25px;
				opacity: 0;
				transform: translateY(50px);
				transition: 0.2s 0.1s ease;
			}
		}
		img {
			width: 100%;
			transition: 0.3s ease;
		}
		h4 {
			margin: 10px 0 0;
			text-align: center;
			font-size: 16px;
			color: $textColor;
		}
	}
	&:first-child {
		margin-top: 0;
	}
	@include mbp(480) {
		&.cols-3,
		&.cols-4 {
			.gallery-item {
				@include column(1/3, $cycle: 3, $gutter: 2);
				margin-bottom: 2%;
			}
		}
	}
	@include mbp(768) {
		&.cols-4 {
			.gallery-item {
				@include column(1/4, $cycle: 4, $gutter: 2);
			}
		}
	}
}
