/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

@mixin mbp($point) {
	@media (min-width: #{$point}px)  { @content; }
}

@mixin bp($point) {
	@media (max-width: #{$point}px)  { @content; }
}

@mixin to-rem($property, $values) {

	// Create a couple of empty lists as output buffers

	$px-values: ();
	$rem-values: ();

	// Loop through the $values list

	@each $value in $values {

		@if $value == 0 or $value == 0px {
			// 0 -- use it without a unit
			$px-values: join($px-values, 0);
			$rem-values: join($rem-values, 0);
		} @else if $value == auto or $value == inherit {
			// auto or inherit - don't add px or rem
			$px-values: join($px-values, $value);
			$rem-values: join($rem-values, $value);
		} @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
			// px value given - calculate rem value from baseFontSize
			$new-rem-value: $value / $baseFontSize;
			$px-values: join($px-values, round($value));
			$rem-values: join($rem-values, #{$new-rem-value}rem);
		} @else {
			// unitless value - use those directly as rem and calculate the px-fallback
			$px-values: join($px-values, round($value * $baseFontSize));
			$rem-values: join($rem-values, #{$value}rem);
		}

	}

	// output the converted rules

	#{$property}: $px-values;
	#{$property}: $rem-values;

}
