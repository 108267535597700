/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.post-nav {
	clear: both;
	text-align: right;
	font-size: 0;
	font-weight: 700;
	a {
		text-decoration: none;
		&:hover {
			background: $color2;
			border-color: $color2;
			color: #FFF;
		}
	}
	.page-numbers {
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		display: inline-block;
		margin: 0 0 0 6px;
		color: $textColor;
		background: #FFF;
		border: 1px solid $textColor;
		font-size: 13px;
	}
	.current {
		background: $color2;
		border-color: $color2;
		color: #FFF;
	}
}
