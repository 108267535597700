/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

.image-content-split {
	width: 100%;
	position: relative;
	background: #FFF;
	.image {
		background: no-repeat center center / cover;
		img {
			width: 100%;
		}
	}
	.text {
		padding: 60px 20px;
	}
	@include mbp(768) {
		display: flex;
		align-items: stretch;
		align-content: center;
		.image {
			width: 50%;
			float: left;
			img {
				display: none;
			}
		}
		.text {
			display: flex;
			align-items: center;
			width: 50%;
			float: right;
			padding: 60px 5%;
			.inner {
				max-width: 660px;
				margin: 0 auto;
			}
		}
		&.alt {
			flex-direction: row-reverse;
			.image {
				float: right;
			}
			.text {
				float: left;
			}
		}
	}
	@include mbp(960) {
		min-height: 700px;
		.text {
			padding: 100px 5%;
		}
	}
	@include mbp(1500) {
		min-height: 960px;
	}
}
